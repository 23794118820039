
  import { mapGetters } from 'vuex'
  import AboutUs from '~/components/contact/aboutUs'
  import ContactStandard from '~/components/contact/standard'
  import RefundDocument from '~/components/contact/refundDocument'

  export default {
    name: `ContactPage`,
    components: { RefundDocument, ContactStandard, AboutUs },
    options: {
      auth: false
    },
    data() {
      return {
        subjectSelected: `questionsAboutTheDossier`
      }
    },
    computed: {
      ...mapGetters({
        isCandidate: `permissions/userIsCandidate`
      }),
      subject() {
        const valueAll = [
          { value: `questionsAboutTheDossier`, text: this.$t(`pages.contact.questions_about_the_dossier`) },
          { value: `technicalProblem`, text: this.$t(`pages.contact.technical_problem`) }
        ]
        const valueIsCandidate =
          this.isCandidate && this.candidateCanRequestReimbursement()
            ? [{ value: `cost`, text: this.$t(`pages.contact.cost`) }]
            : []
        const valueOther = [{ value: `other`, text: this.$t(`pages.contact.other`) }]
        return [...valueAll, ...valueIsCandidate, ...valueOther]
      }
    },
    methods: {
      candidateCanRequestReimbursement() {
        const permissions = this.$store.getters[`permissions/getCurrentPermissions`] || []
        return permissions.includes(`can_use_candidate_reimbursement_feature`)
      }
    }
  }
